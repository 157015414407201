<template>
  <div class="">
    <div class="main_section" ref="home_el">
      <div class="main_background"></div>
      <div class="main_content">
        <h1 class="main_content-title">TRAKILOS: <br>
          <i>Tracking Logistics</i>
        </h1>
        <p class="main_content-sub">{{$t('main_page.first_logistics_storage_platform')}}</p>
        <button class="main_content-btn" @click="scrollTo('contact_el')">{{$t('main_page.start')}}</button>
      </div>
      <div class="main_bottom-content">
        <div class="main_bottom-item connect">
          <span class="main_bottom-item_text">{{$t('main_page.connect')}}</span>
        </div>
        <div class="main_bottom-item storage">
          <span class="main_bottom-item_text">{{$t('main_page.storage')}}</span>
        </div>
        <div class="main_bottom-item deliver">
          <span class="main_bottom-item_text">{{$t('main_page.deliver')}}</span>
        </div>
      </div>
    </div>

    <div class="service_section" ref="service_el">
      <div class="service_content">

        <h2 class="service_title">{{$t('main_page.services')}}</h2>
        <p class="service_sub">{{$t('main_page.join_spains_leading')}}</p>

        <div class="service_content-block">
          <div class="service_items">
            <ul class="service_items-group">
              <li class="service_item">{{$t('main_page.logistic_storage')}}</li>
              <li class="service_item">{{$t('main_page.e_commerce_logistics')}}</li>
              <li class="service_item">{{$t('main_page.customs_warehouse')}}</li>
              <li class="service_item">{{$t('main_page.OPS')}}</li>
              <li class="service_item">{{$t('main_page.ADR_torage')}}</li>
              <li class="service_item">{{$t('main_page.cold_storage')}}</li>
              <li class="service_item">{{$t('main_page.frozen_storage')}}</li>
              <li class="service_item">{{$t('main_page.pharma_torage')}}</li>
              <li class="service_item">{{$t('main_page.many_more_services')}}</li>
            </ul>
            <button class="service_items-btn" @click="scrollTo('contact_el')">{{$t('main_page.join_and_discover')}}</button>
          </div>
          <div class="service_img"></div>
        </div>

        <div class="service_map-block">
          <div class="service_map-content">
            <h2 class="service_map-title">{{$t('main_page.keep_track_business')}}</h2>
            <h3 class="service_map-sub"><i>{{$t('main_page.store_and_deliver_effortlessly')}}</i></h3>
            <p class="service_map-description">{{$t('main_page.find_space_and_storage')}}</p>
            <button class="service_items-btn" @click="scrollTo('contact_el')">{{$t('main_page.connect_now')}}</button>
          </div>
          <div class="service_map-background"></div>
        </div>

        <div class="client_section">
          <h1 class="client_love-title">{{$t('main_page.our_clients')}} <span class="client_love-img"></span> <span v-if="$i18n.locale == 'en' "> us</span></h1>
          <div class="client_review-content">
            <div class="client_review-points">
              <h1 class="client_review-point">126 +</h1>
              <h3 class="client_review-point_text">{{$t('main_page.review_from_clients')}}</h3>
            </div>
            <div class="client_review-boxes">
              <div class="client_review-box">
                <div class="client_review-box_icon"></div>
                <h5 class="client_review-box_name">Carlos Fernández</h5>
                <p class="client_review-box_description">{{$t('main_page.carlos_review')}}</p>
              </div>
              <div class="client_review-box">
                <div class="client_review-box_icon"></div>
                <h5 class="client_review-box_name">Javier López</h5>
                <p class="client_review-box_description">{{$t('main_page.javier_review')}}</p>
              </div>
              <div class="client_review-box">
                <div class="client_review-box_icon"></div>
                <h5 class="client_review-box_name">Laura Martínez</h5>
                <p class="client_review-box_description">{{$t('main_page.laura_review')}}</p>
              </div>
            </div>
          </div>

          <h1 class="client_title">{{$t('main_page.cities_currently_serving')}}</h1>
          <div class="client_serving">
            <div class="client_serving-location first_block">
              <h3 class="client_serving-title">{{$t('main_page.cities_we_are_currently_serving')}} </h3>
              <div class="client_location">
                <div class="client_location-block">
                  <span class="client_location-icon"></span>
                  {{$t('main_page.barcelona')}}
                </div>
                <div class="client_location-block">
                  <span class="client_location-icon"></span>
                  {{$t('main_page.madrid')}}
                </div>
              </div>
            </div>

            <div class="client_serving-location">
              <h3 class="client_serving-title">{{$t('main_page.coming_soon')}} </h3>
              <div class="client_location">
                <div class="client_location-block soft">
                  <span class="client_location-icon"></span>
                  {{$t('main_page.seville')}}
                </div>
                <div class="client_location-block soft">
                  <span class="client_location-icon"></span>
                  {{$t('main_page.valencia')}}
                </div>
                <div class="client_location-block soft">
                  <span class="client_location-icon"></span>
                  {{$t('main_page.saragossa')}}
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="work_section" ref="work_el">
          <h1 class="work_title">{{$t('main_page.how_we_work')}}</h1>
          <p class="work_sub">{{$t('main_page.streamline_your_logistics')}}</p>

          <div class="work_links">
            <div class="work_link-item" :class="{'line': work_status}" @click="changeWork()">{{$t('main_page.e_tailored_solutions')}}</div>
            <div class="work_link-item" :class="{'line': !work_status}" @click="changeWork()">{{$t('main_page.b2b_tailored_solutions')}}</div>
          </div>

          <div class="work_boxes" :class="{'active': work_status}">
            <div class="work_box-item">
              <span class="work_box-icon platform"></span>
              <p class="work_box-text">{{$t('main_page.connect_with_our_platform')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon storage"></span>
              <p class="work_box-text">{{$t('main_page.storage_immediately')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon warehouse"></span>
              <p class="work_box-text">{{$t('main_page.choose_your_best_warehouse')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon track"></span>
              <p class="work_box-text">{{$t('main_page.keep_track')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon pick"></span>
              <p class="work_box-text">{{$t('main_page.pack_&_ship')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon deliver"></span>
              <p class="work_box-text">{{$t('main_page.deliver_box')}}</p>
            </div>
          </div>

          <div class="work_boxes" :class="{'active': !work_status}">
            <div class="work_box-item">
              <span class="work_box-icon platform"></span>
              <p class="work_box-text">{{$t('main_page.connect_with_our_platform')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon storage"></span>
              <p class="work_box-text">{{$t('main_page.storage_immediately')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon track"></span>
              <p class="work_box-text">{{$t('main_page.keep_track')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon stock"></span>
              <p class="work_box-text">{{$t('main_page.stock_box')}}</p>
            </div>
            <div class="work_box-item">
              <span class="work_box-icon deliver"></span>
              <p class="work_box-text">{{$t('main_page.deliver_box_optional')}}</p>
            </div>
          </div>

          <div class="work_btn-container">
            <button class="work_btn" @click="scrollTo('contact_el')">{{$t('main_page.join_and_discover')}}</button>
          </div>

        </div>

        <div class="service_map-block logistics">
          <div class="service_map-background logistic_map"></div>
          <div class="service_map-content">
            <div class="service_map-dual">
              <h3 class="service_map-sub">{{$t('main_page.keep_your_logistics_moving')}} <span class="service_map-title"><i>{{$t('main_page.simple_integration')}}</i></span>
              </h3>
            </div>
            <p class="service_map-description">{{$t('main_page.join_spains_top_storage')}}</p>
            <button class="service_items-btn" @click="scrollTo('contact_el')">{{$t('main_page.connect_now')}}</button>
          </div>
        </div>

        <div class="trakilos_section">
          <h1 class="trakilos_title">{{$t('main_page.why_trakilos')}}</h1>
          <p class="trakilos_description">{{$t('main_page.discover_new_growth_opportunities')}}</p>

          <div class="trakilos_carousel">

            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              <div class="carousel_controls-content">
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <!--                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
                  <span class="sr-only">Previous</span>
                </a>
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">1</li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1">2</li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2">3</li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="3">4</li>
                </ol>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <!--                  <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
                  <span class="sr-only">Next</span>
                </a>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="carousel-item_data">
                    <h4 class="carousel-item_title">{{$t('carousel_text.immediate_availability')}}</h4>
                    <p class="carousel-item_description">
                      {{$t('carousel_text.immediate_text_1')}}<br><br>
                      {{$t('carousel_text.immediate_text_2')}}<br>
                    </p>
                  </div>
                  <img class="d-block" src="../../src/assets/images/main_carousel_1.png" alt="First slide">
                </div>
                <div class="carousel-item">
                  <div class="carousel-item_data">
                    <h4 class="carousel-item_title">{{$t('carousel_text.simple')}}</h4>
                    <p class="carousel-item_description">
                      {{$t('carousel_text.simple_text_1')}}<br><br>
                      {{$t('carousel_text.simple_text_2')}}<br><br>
                      {{$t('carousel_text.simple_text_3')}}<br>
                    </p>
                  </div>
                  <img class="d-block" src="../../src/assets/images/main_carousel_2.png" alt="Second slide">
                </div>
                <div class="carousel-item">
                  <div class="carousel-item_data">
                    <h4 class="carousel-item_title">{{$t('carousel_text.safe')}}</h4>
                    <p class="carousel-item_description">
                      {{$t('carousel_text.safe_text_1')}}<br><br>
                      {{$t('carousel_text.safe_text_2')}}<br>
                    </p>
                  </div>
                  <img class="d-block" src="../../src/assets/images/main_carousel_3.png" alt="Third slide">
                </div>
                <div class="carousel-item">
                  <div class="carousel-item_data">
                    <h4 class="carousel-item_title">{{$t('carousel_text.grow')}}</h4>
                    <p class="carousel-item_description">
                      {{$t('carousel_text.grow_text_1')}}<br><br>
                      {{$t('carousel_text.grow_text_2')}}<br>
                    </p>
                  </div>
                  <img class="d-block" src="../../src/assets/images/main_carousel_4.png" alt="Fourth slide">
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="steps_section">
          <h1 class="steps_title">{{$t('main_page.get_started_in_just_3_steps')}}</h1>
          <div class="steps_boxes">
            <div class="steps_box-item">
              <div class="steps_box-icon step_1"></div>
              <span class="steps_box-text">{{$t('main_page.connect_with_our_platform')}}</span>
            </div>
            <div class="steps_box-item">
              <div class="steps_box-icon step_2"></div>
              <span class="steps_box-text">{{$t('main_page.choose_your_best_warehouse')}}</span>
            </div>
            <div class="steps_box-item">
              <div class="steps_box-icon step_3"></div>
              <span class="steps_box-text">{{$t('main_page.start_growing')}}</span>
            </div>
          </div>
        </div>

        <div class="video_section">
          <h1 class="video_title">{{$t('main_page.are_you_a_storage_provider')}}</h1>
          <div class="video_img">
            <div class="player__sizer">
              <video width="100%" height="100%" controls >
                <source src="/vid.mov" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>

          </div>
          <button class="video_btn" @click="toPartner('partner_el')">{{$t('main_page.parnet_with_us')}}</button>
        </div>

        <div class="contact_section" ref="contact_el">
          <h1 class="contact_title">{{$t('main_page.contact_us')}}</h1>
          <form @submit.prevent="sendContactData()">
            <div class="contact_inputs">
              <div class="contact_dual-content">
                <div class="input_item">
                  <label for="">{{$t('main_page.first_name')}}</label>
                  <input type="text" v-model="contact_data.first_name">
                  <span v-if="dataErrors['first_name']" class="text_danger mt-2">{{dataErrors['first_name'][0]}}</span>
                </div>
                <div class="input_item">
                  <label for="">{{$t('main_page.last_name')}}</label>
                  <input type="text" v-model="contact_data.last_name">
                  <span v-if="dataErrors['last_name']" class="text_danger mt-2">{{dataErrors['last_name'][0]}}</span>
                </div>
              </div>

              <div class="contact_dual-content">
                <div class="input_item">
                  <label for="">{{$t('main_page.email')}}</label>
                  <input type="email" v-model="contact_data.email">
                  <span v-if="dataErrors['email']" class="text_danger mt-2">{{dataErrors['email'][0]}}</span>
                </div>
                <div class="input_item">
                  <label for="">{{$t('main_page.phone_number')}}</label>
                  <input type="number" v-model="contact_data.phone_number">
                  <span v-if="dataErrors['phone_number']" class="text_danger mt-2">{{dataErrors['phone_number'][0]}}</span>
                </div>
              </div>

              <div class="contact_checkbox-container">
                <label class="check_label">{{$t('main_page.what_are_you_looking_for')}}</label>
                <div class="check_block">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="contact_data.hassle_free_storage" :true-value="1" :false-value="0">
                    <label class="form-check-label" for="inlineCheckbox1">{{$t('main_page.hassle_free_storage')}}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" v-model="contact_data.ecommerce_solutions" :true-value="1" :false-value="0">
                    <label class="form-check-label" for="inlineCheckbox2">{{$t('main_page.e_commerce_solutions')}}</label>
                  </div>
                </div>
                <div class="check_block">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" v-model="contact_data.general_information" :true-value="1" :false-value="0">
                    <label class="form-check-label" for="inlineCheckbox3">{{$t('main_page.general_information')}}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox4" v-model="contact_data.storage_provider_collaborate" :true-value="1" :false-value="0">
                    <label class="form-check-label" for="inlineCheckbox4">{{$t('main_page.im_a_storage_provider_eager_to_collaborate')}}</label>
                  </div>
                </div>
              </div>
              <div class="contact_message-container">
                <label class="form-check-label">{{$t('main_page.message')}}</label>
                <textarea name="" id="" cols="30" rows="1" :placeholder="$t('main_page.type_your_message')" v-model="contact_data.message"></textarea>
                <span v-if="dataErrors['message']" class="text_danger mt-2">{{dataErrors['message'][0]}}</span>
                <div class="contact_message-btn_container">
                  <button class="contact_message-btn" type="submit">{{$t('main_page.send')}}</button>
                </div>
              </div>
            </div>
            <p v-if="successMessage !==''" class="success_msg">{{successMessage}}</p>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
  name: 'HomeView',
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      work_status: true,
      dataErrors: [],
      successMessage: '',
      contact_data: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        message: '',
        hassle_free_storage: 0,
        ecommerce_solutions: 0,
        general_information: 0,
        storage_provider_collaborate: 0
      }
    }
  },
  validations() {
    return {
      contact_data: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        phone_number: { required },
        message: { required },
        hassle_free_storage: { required },
        ecommerce_solutions: { required },
        general_information: { required },
        storage_provider_collaborate: { required }
      }
    }
  },
  components: {
  },
  computed: {
    ...mapState(['shouldScroll', 'scroll_element']),  // Map the Vuex state
  },
  watch: {
    shouldScroll(newValue) {
      if (newValue) {
        this.scrollToElement();
        this.resetScroll();  // Reset the scroll state after scrolling
      }
    }
  },
  methods: {
    ...mapActions(['resetScroll', 'triggerScroll', 'sendMail']),
    scrollToElement() {
      this.$refs[this.scroll_element].scrollIntoView({ behavior: 'smooth' });
    },
    changeWork() {
      this.work_status = !this.work_status
    },
    scrollTo(el) {
      setTimeout(() => {
        this.triggerScroll(el);
      }, 500)
    },
    toPartner(el) {
      this.$router.push('/partner')
      setTimeout(() => {
        this.triggerScroll(el);
      }, 500)
    },
    sendContactData() {
      this.dataErrors = []
      this.sendMail(this.contact_data)
      .then(res => {
        if(res.status == 422) {
          this.dataErrors = res.response.data
        } else {
          this.successMessage = res.data.message
          setTimeout(() => {
            this.successMessage = ''
          }, 5000)
            this.contact_data.first_name = ''
            this.contact_data.last_name = ''
            this.contact_data.email = ''
            this.contact_data.phone_number = ''
            this.contact_data.message = ''
            this.contact_data.hassle_free_storage = 0
            this.contact_data.ecommerce_solutions = 0
            this.contact_data.general_information = 0
            this.contact_data.storage_provider_collaborate = 0


        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.main {
  &_section {
    position: relative;
  }

  &_background {
    width: 100%;
    height: 100vh;
    background: url("~@/assets/images/main_background.png") no-repeat center;
    background-size: cover;
  }

  &_content {
    position: absolute;
    margin: 0 auto;
    top: 30%;
    left: 50%;
    z-index: 1;
    @media screen and (max-width: 768px) {
      top: 20%;
      left: 25%;
    }
    @media screen and (max-width: 430px) {
      left: 10%;
    }


    &-title {
      color: #fff;
      font-size: 64px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      @media screen and (max-width: 430px) {
        font-size: 38px;
      }

    }

    &-sub {
      padding: 40px 0;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      @media screen and (max-width: 430px) {
        font-size: 18px;
      }

    }

    &-btn {
      border-radius: 40px;
      border: 4px solid #222724;
      background: rgba(34, 39, 36, 0.50);
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 14px 30px;
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
      @media screen and (max-width: 430px) {
        margin-top: 30px;
      }
    }
  }

  &_bottom {
    &-content {
      width: 100%;
      height: 400px;
      background: linear-gradient(180deg, rgba(42, 44, 39, 0.00) 0%, #2A2C27 100%);
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @media screen and (max-width: 1200px) {
        height: 300px;
      }
      @media screen and (max-width: 768px) {
        justify-content: space-evenly;
      }
      @media screen and (max-width: 430px) {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 40px;
      }
    }

    &-item {
      width: 100px;
      height: 100px;
      margin: 80px 160px;
      position: relative;
      @media screen and (max-width: 1440px) {
        margin: 70px 140px;
      }
      @media screen and (max-width: 1200px) {
        margin: 50px 100px;
        width: 80px;
        height: 80px;
      }
      @media screen and (max-width: 768px) {
        width: 70px;
        margin: 50px 80px 50px 0;
      }
      @media screen and (max-width: 430px) {
        margin: 0;
      }

      &_text {
        position: absolute;
        right: -120px;
        top: 40%;
        color: #fff;
        font-size: 24px;
        @media screen and (max-width: 1200px) {
          right: -100px;
        }
      }

      &.connect {
        background: url("~@/assets/icons/connect_icon.svg") no-repeat center;
        background-size: contain;
      }

      &.storage {
        background: url("~@/assets/icons/storage_icon.svg") no-repeat center;
        background-size: contain;
      }

      &.deliver {
        background: url("~@/assets/icons/deliver_icon.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
}

.service {
  &_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EFEFEF;
  }

  &_content {
    width: 100%;
    padding: 100px 0;
    @media screen and (max-width: 1440px) {
      padding: 80px 0;
    }
    @media screen and (max-width: 430px) {
      padding: 30px 0;
    }

    &-block {
      display: flex;
      justify-content: center;
      padding: 80px 0;
      @media screen and (max-width: 1440px) {
        padding: 60px 0;
        justify-content: space-between;
      }
      @media screen and (max-width: 768px) {
        padding: 40px 0;
      }
      @media screen and (max-width: 430px) {
        flex-direction: column;
        padding: 20px 0;
      }

    }
  }

  &_title {
    color: #751813;
    font-size: 64px;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    @media screen and (max-width: 1440px) {
      font-size: 52px;
    }
    @media screen and (max-width: 430px) {
      font-size: 34px;
    }
  }

  &_sub {
    color: #222724;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    padding: 4px 0;
    @media screen and (max-width: 768px) {
      max-width: 60%;
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
    @media screen and (max-width: 430px) {
      max-width: 80%;
      font-size: 16px;
    }
  }

  &_img {
    margin-left: 90px;
    width: 893px;
    height: 589px;
    background: url("~@/assets/images/service_background.png") no-repeat center;
    @media screen and (max-width: 1440px) {
      width: 800px;
      height: 530px;
    }
    @media screen and (max-width: 1200px) {
      width: 600px;
      height: 450px;
      background-size: contain;
    }
    @media screen and (max-width: 768px) {
      margin-left: 20px;
      width: 420px;
      height: 320px;
    }
  }

  &_items {
    padding: 0 90px;
    @media screen and (max-width: 1440px) {
      padding: 0 40px 0 90px;
    }
    @media screen and (max-width: 768px) {
      padding: 0 20px 0 30px;
    }

    &-group {
      @media screen and (max-width: 1440px) {
        padding-left: 10px;
      }
    }

    &-btn {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 40px;
      border: 4px solid var(--red, #751813);
      background: rgba(122, 28, 15, 0.50);
      padding: 14px 30px;
      margin-top: 60px;
      @media screen and (max-width: 1680px) {
        margin-top: 50px;
      }
      @media screen and (max-width: 1200px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 20px;
        font-size: 20px;
        padding: 10px 20px;
      }
      @media screen and (max-width: 430px) {
        margin: 10px 0;
      }
    }
  }

  &_item {
    color: #222724;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 300px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 5px 0;
    @media screen and (max-width: 1680px) {
      font-size: 24px;
      font-weight: 600;
    }
    @media screen and (max-width: 1200px) {
     font-size: 20px;
    }
    @media screen and (max-width: 768px) {
      width: 270px;
      font-size: 18px;
    }

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      margin-right: 14px;
      border-radius: 40px;
      border: 2px solid #751813;
      background: rgba(122, 28, 15, 0.50);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      @media screen and (max-width: 768px) {
        width: 15px;
        height: 15px;
        padding: 6px;
      }
    }

    &:last-child::before {
      content: '';
      width: 20px;
      height: 20px;
      background: transparent;
      border: none;
      margin-left: 18px;
      @media screen and (max-width: 1680px) {
        margin-left: 0;
      }
    }
  }

  &_map {
    &-dual {
      display: flex;
      flex-wrap: wrap;

      & .service_map-title,
      & .service_map-sub {
        flex-wrap: wrap;
      }
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 430px) {
        flex-direction: column;
      }

      &.logistics {
        padding-top: 80px;
        @media screen and (max-width: 768px) {
          padding-top: 40px;
        }
      }
    }

    &-content {
      padding: 0 140px;
      @media screen and (max-width: 1440px) {
        padding: 0 70px 0 100px;
      }
      @media screen and (max-width: 1200px) {
        padding: 0 50px 0 70px;
      }
      @media screen and (max-width: 768px) {
        padding: 0 30px;
      }
    }

    &-title {
      color: #751813;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media screen and (max-width: 1680px) {
        font-size: 56px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 48px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 44px;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }

    &-sub {
      font-size: 64px;
      font-weight: 600;
      line-height: normal;
      color: #222724;
      max-width: 705px;
      @media screen and (max-width: 1680px) {
        font-size: 56px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 48px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 44px;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }

    &-description {
      padding-top: 40px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      max-width: 760px;
      @media screen and (max-width: 1680px) {
        padding-top: 30px;
      }
      @media screen and (max-width: 768px) {
        padding-top: 20px;
      }
    }

    &-background {
      width: 920px;
      height: 650px;
      background: url("~@/assets/images/dashboard_map.png") no-repeat right;
      background-size: contain;
      @media screen and (max-width: 1680px) {
        width: 900px;
        height: 600px;
      }
      @media screen and (max-width: 768px) {
        height: 400px;
      }
      @media screen and (max-width: 430px) {
        width: 100%;
        height: 300px;
      }

      &.logistic_map {
        width: 840px;
        background: url("~@/assets/images/logistics_map.png") no-repeat left;
        background-size: contain;
        @media screen and (max-width: 1680px) {
          width: 750px;
        }
        @media screen and (max-width: 430px) {
          width: 100%;
        }
      }
    }
  }
}

.client {
  &_section {
    display: flex;
    flex-direction: column;
    padding: 50px 140px;
    align-items: center;
    @media screen and (max-width: 1680px) {
      padding: 40px 90px;
    }
    @media screen and (max-width: 1200px) {
      padding: 30px 50px;
    }
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
  }

  &_review {
    &-content {
      display: flex;
      padding: 100px 0;
      @media screen and (max-width: 1440px) {
        padding: 70px 0;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 30px 0;
      }
      @media screen and (max-width: 430px) {
        padding: 20px 0;
      }
    }

    &-points {
      padding: 0 80px;
      @media screen and (max-width: 1680px) {
        padding: 0 20px;
      }
      @media screen and (max-width: 1200px) {
        padding: 0 15px;
      }
      @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
      @media screen and (max-width: 430px) {
        justify-content: center;
      }
    }

    &-point {
      color: #222724;
      font-size: 160px;
      font-weight: 900;
      line-height: normal;
      @media screen and (max-width: 1680px) {
        font-size: 110px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 76px;
        margin-top: 30px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 50px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 0;
        margin-right: 20px;
      }

      &_text {
        color: #222724;
        font-size: 57px;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 1680px) {
          font-size: 44px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 30px;
        }
        @media screen and (max-width: 1200px) {
          font-size: 22px;
        }
      }
    }

    &-boxes {
      display: flex;
      @media screen and (max-width: 430px) {
        flex-direction: column;
      }
    }

    &-box {
      border-radius: 20px;
      background: #FFF;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
      margin: 10px;
      padding: 30px;
      max-width: 326px;
      @media screen and (max-width: 1440px) {
        padding: 20px;
        max-width: 300px;
      }
      @media screen and (max-width: 1200px) {
        max-width: 260px;
      }
      @media screen and (max-width: 768px) {
        max-width: 210px;
        padding: 15px;
        margin: 8px;
      }
      @media screen and (max-width: 430px) {
        max-width: 100%;
      }


      &_icon {
        width: 60px;
        height: 60px;
        background: url("~@/assets/icons/quotation_mark.svg") no-repeat center;
      }

      &_name {
        color: #1E1E1C;
        font-size: 24px;
        font-weight: bold;
        line-height: normal;
        padding: 20px 0;
        @media screen and (max-width: 1440px) {
          font-size: 20px;
          padding: 15px 0;
        }
      }

      &_description {
        color: #1E1E1C;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        @media screen and (max-width: 1680px) {
          line-height: 25px;
          font-weight: 500;
          font-size: 19px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          line-height: 22px;
        }
        @media screen and (max-width: 1200px) {
          line-height: 20px;
        }
      }
    }
  }

  &_title {
    color: #751813;
    font-size: 64px;
    font-weight: bold;
    line-height: normal;
    padding-bottom: 40px;
    @media screen and (max-width: 1440px) {
      padding-bottom: 25px;
      font-size: 52px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 44px;
    }
    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 430px) {
      font-size: 26px;
      text-align: center;
      padding-bottom: 10px;
    }
  }

  &_love {
    &-title {
      display: flex;
      align-items: center;
      color: #222724;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media screen and (max-width: 1440px) {
        font-size: 52px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 44px;
      }
      @media screen and (max-width: 768px) {
        font-size: 34px;
      }
      @media screen and (max-width: 430px) {
        font-size: 30px;
      }
    }

    &-img {
      display: block;
      width: 54px;
      height: 54px;
      background: url("~@/assets/icons/love_icon.svg");
      margin: 0 12px;
    }
  }

  &_serving {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    &-title {
      color: #222724;
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 20px;
      @media screen and (max-width: 768px) {
        font-size: 26px;
      }
    }

    &-location {
      @media screen and (max-width: 768px) {
        padding: 20px 0 10px;
      }
      &.first_block {
        border-right: 2px solid #D2332B;
        @media screen and (max-width: 768px) {
          border-right: none;
          border-bottom: 2px solid #D2332B;
        }
        & .client_serving-title {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }

  &_location {
    display: flex;

    &-block {
      padding: 20px 60px;
      display: flex;
      align-items: center;
      color: #2A2C27;
      font-weight: 600;
      font-size: 24px;
      line-height: normal;
      @media screen and (max-width: 1200px) {
        font-size: 20px;
        padding: 15px 40px;
      }
      @media screen and (max-width: 768px) {
        padding: 10px 25px;
      }
      @media screen and (max-width: 430px) {
        padding: 5px 15px;
        font-size: 18px;
      }

      &.soft {
        opacity: 0.6;
      }
    }

    &-icon {
      display: block;
      width: 40px;
      height: 40px;
      background: url("~@/assets/icons/location_icon.svg") no-repeat center;
      margin: 0 10px;
      @media screen and (max-width: 430px) {
        width: 30px;
        height: 30px;
        background-size: contain;
      }
    }
  }
}

.work {
  &_section {
    width: 100%;
    padding: 130px 0;
    background: #1E1E1C;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      padding: 80px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 60px 0;
    }
    @media screen and (max-width: 430px) {
      padding: 30px 0;
    }
  }

  &_title {
    color: #fff;
    font-size: 64px;
    font-weight: bold;
    line-height: normal;
    padding-bottom: 20px;
    @media screen and (max-width: 1440px) {
      font-size: 52px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 44px;
    }
    @media screen and (max-width: 768px) {
      font-size: 34px;
    }
    @media screen and (max-width: 430px) {
      font-size: 28px;
    }
  }

  &_sub {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    padding-bottom: 70px;
    @media screen and (max-width: 1440px) {
      padding-bottom: 40px;
    }
    @media screen and (max-width: 1200px) {
      padding-bottom: 25px;
    }
    @media screen and (max-width: 768px) {
      padding-bottom: 15px;
      font-size: 18px;
    }
    @media screen and (max-width: 430px) {
      font-size: 16px;
      text-align: center;
      max-width: 85%;
    }
  }

  &_links {
    display: flex;
  }

  &_link {
    &-item {
      color: #FFF;
      font-size: 32px;
      font-style: normal;
      line-height: normal;
      padding: 5px 30px;
      cursor: pointer;
      @media screen and (max-width: 1440px) {
        font-size: 26px;
        padding: 5px 25px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 22px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
      @media screen and (max-width: 430px) {
        font-size: 16px;
        text-align: center;
      }

      &.line {
        font-weight: 700;
        border-bottom: 1px solid #D2332B;
        @media screen and (max-width: 768px) {
          font-weight: 600;
        }
      }
    }
  }

  &_boxes {
    display: none;
    padding: 50px 0;
    &.active {
      display: flex;
    }
    @media screen and (max-width: 1440px) {
      padding: 30px 0;
    }
    @media screen and (max-width: 1200px) {
      padding: 20px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 10px 30px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &_box {
    &-item {
      margin: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 170px;
      @media screen and (max-width: 1440px) {
        width: 150px;
      }
      @media screen and (max-width: 1200px) {
        width: 120px;
      }
      @media screen and (max-width: 768px) {
        margin: 30px 40px;
      }
      @media screen and (max-width: 430px) {
        margin: 20px 25px;
      }
    }

    &-icon {
      width: 84px;
      height: 84px;
      @media screen and (max-width: 1440px) {
        width: 75px;
        height: 75px;
      }
      @media screen and (max-width: 1200px) {
        width: 65px;
        height: 65px;
      }

      &.platform {
        background: url("~@/assets/icons/connect.svg") no-repeat center;
        background-size: cover;
      }

      &.storage {
        background: url("~@/assets/icons/storage_box.svg") no-repeat center;
        background-size: cover;
      }

      &.track {
        background: url("~@/assets/icons/track.svg") no-repeat center;
        background-size: cover;
      }

      &.pick {
        background: url("~@/assets/icons/pick_pack.svg") no-repeat center;
        background-size: cover;
      }

      &.deliver {
        background: url("~@/assets/icons/deliver.svg") no-repeat center;
        background-size: cover;
      }

      &.warehouse {
        background: url("~@/assets/icons/warehouse.svg") no-repeat center;
        background-size: cover;
      }

      &.stock {
        background: url("~@/assets/icons/stock.svg") no-repeat center;
        background-size: cover;
      }
    }

    &-text {
      color: #A9A9A9;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;
      margin-top: 40px;
      @media screen and (max-width: 1440px) {
        margin-top: 30px;
        font-size: 20px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 17px;
        margin-top: 20px;
      }
    }
  }

  &_btn {
    color: #FFF;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    border-radius: 40px;
    border: 4px solid #751813;
    background: rgba(122, 28, 15, 0.50);
    padding: 14px 30px;
    @media screen and (max-width: 430px) {
      font-size: 20px;
    }

    &-container {
      padding: 20px 0 10px;
    }
  }
}

.trakilos {
  &_section {
    padding: 100px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      padding: 50px 0 80px;
    }
    @media screen and (max-width: 768px) {
      padding: 40px 0 70px;
    }
    @media screen and (max-width: 430px) {
      padding: 30px 0 50px;
    }

  }

  &_title {
    color: #751813;
    text-align: center;
    font-size: 64px;
    font-weight: 900;
    line-height: normal;
    @media screen and (max-width: 1440px) {
      font-size: 52px;
    }
    @media screen and (max-width: 1200px) {
     font-size: 44px;
    }
    @media screen and (max-width: 768px) {
      font-size: 34px;
    }
  }

  &_description {
    color: #222724;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    padding: 40px 0;
    @media screen and (max-width: 1440px) {
      padding: 30px 0;
      font-size: 26px;
    }
    @media screen and (max-width: 768px) {
      font-size: 24px;
      padding: 20px 0;
    }
    @media screen and (max-width: 430px) {
      font-size: 20px;
      text-align: center;
    }
  }
  &_carousel {
    display: flex;
    width: 100%;
    & .carousel {
      &-item {
        display: flex;
        justify-content: space-between;
        opacity: 0;
        &.active {
          z-index: 1;
          opacity: 1;
        }
        @media screen and (max-width: 430px) {
          flex-direction: column;
          padding: 0 5px;
        }
        &_title {
          color: #222724;
          font-size: 32px;
          font-weight: bold;
          line-height: normal;
          padding-bottom: 40px;
          @media screen and (max-width: 768px) {
            font-size: 26px;
            padding-bottom: 15px;
          }
        }
        &_description {
          color: #222724;
          font-size: 20px;
          font-weight: 500;
          line-height: 26px; /* 130% */
          max-width: 800px;
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        &_data {
          padding: 100px 110px 0 200px;
          @media screen and (max-width: 1680px) {
            padding: 80px 90px 0 170px;
          }
          @media screen and (max-width: 1440px) {
            padding: 60px 80px 0 120px;
          }
          @media screen and (max-width: 768px) {
            padding: 30px 30px 0 30px;
          }
          @media screen and (max-width: 430px) {
            padding: 0 25px;
          }
        }
        & img {
          width: 900px;
          height: 650px;
          background-size: cover;
          border-radius: 12px 0 0 12px;
          @media screen and (max-width: 1680px) {
            width: 840px;
            height: 560px;
          }
          @media screen and (max-width: 1440px) {
            width: 800px;
            height: 500px;
          }
          @media screen and (max-width: 768px) {
            width: 350px;
            height: 260px;
          }
          @media screen and (max-width: 430px) {
            width: 100%;
          }
        }
      }
      &_controls {
        &-content {
          position: absolute;
          bottom: -100px;
          right: 5%;
          width: 400px;
          height: 60px;
          @media screen and (max-width: 768px) {
            bottom: -70px;
          }
          ::v-deep .carousel-indicators {
            max-width: 200px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            list-style-type: none;
            margin: 0 auto;
            & li {
              color: #222724;
              font-size: 22px;
              font-style: italic;
              font-weight: 500;
              line-height: 160%; /* 32px */
              padding: 12px 22px;
              cursor: pointer;
              &.active {
                border-radius: 30px;
                background: #ECECEC;
                box-shadow: 0 4px 9px -4px rgba(52, 52, 52, 0.20);
                color: #D2332B;
                font-size: 32px;
                font-weight: bold;
                line-height: normal;
              }
            }
          }
          & a {
            color: #222724;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            height: 60px;
            text-decoration: none;
          }
        }
      }


    }
  }
}

.steps {
  &_section {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding: 40px 0;
    }
    @media screen and (max-width: 430px) {
      padding: 50px 0 10px;
    }
  }

  &_title {
    color: #751813;
    text-align: center;
    font-size: 64px;
    font-weight: 900;
    line-height: normal;
    padding-bottom: 70px;
    @media screen and (max-width: 1440px) {
      padding-bottom: 50px;
      font-size: 52px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 44px;
    }
    @media screen and (max-width: 768px) {
      font-size: 34px;
    }
    @media screen and (max-width: 430px) {
      font-size: 30px;
      padding-bottom: 30px;
    }
  }

  &_boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 430px) {
      flex-wrap: wrap;
    }
  }

  &_box {
    &-item {
      width: 315px;
      height: 260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 75px;
      @media screen and (max-width: 1440px) {
        margin: 0 40px;
        width: 290px;
        height: 200px;
      }
      @media screen and (max-width: 1200px) {
        margin: 0 25px;
        width: 260px;
        height: 180px;
      }
      @media screen and (max-width: 768px) {
        margin: 0 20px;
        width: 200px;
        height: 150px;
        justify-content: flex-start;
      }
    }

    &-icon {
      width: 227px;
      height: 172px;
      display: flex;
      @media screen and (max-width: 1440px) {
        width: 200px;
        height: 160px;
      }
      @media screen and (max-width: 768px) {
        width: 120px;
        height: 95px;
      }

      &.step_1 {
        background: url("~@/assets/icons/step_1.svg") no-repeat center;
        background-size: contain;
      }

      &.step_2 {
        background: url("~@/assets/icons/step_2.svg") no-repeat center;
        background-size: contain;
      }

      &.step_3 {
        background: url("~@/assets/icons/step_3.svg") no-repeat center;
        background-size: contain;
      }
    }

    &-text {
      color: #1E1E1C;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      padding-top: 60px;
      @media screen and (max-width: 1440px) {
        padding-top: 40px;
        font-size: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
        padding-top: 15px;
      }
      @media screen and (max-width: 430px) {
        padding-top: 5px;
      }
    }
  }
}

.video {
  &_section {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding-top: 30px;
    }
    @media screen and (max-width: 430px) {
      padding: 10px 0 0;
    }
  }

  &_title {
    color: #751813;
    text-align: center;
    font-size: 64px;
    font-weight: 900;
    line-height: normal;
    padding-bottom: 70px;
    @media screen and (max-width: 1440px) {
      padding-bottom: 40px;
      font-size: 52px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 44px;
    }
    @media screen and (max-width: 768px) {
      font-size: 34px;
      padding-bottom: 20px;
    }
    @media screen and (max-width: 430px) {
      font-size: 30px;
      padding-bottom: 10px;
    }
  }

  &_img {
    width: 1220px;
    height: 690px;
    @media screen and (max-width: 1680px) {
      width: 1100px;
      height: 660px;
    }
    @media screen and (max-width: 1440px) {
      width: 900px;
      height: 600px;
    }
    @media screen and (max-width: 768px) {
      width: 600px;
      height: 360px;
    }
    @media screen and (max-width: 430px) {
      width: 100%;
      height: 240px;
      padding: 0 5px;
    }
    & .player__sizer{
      width: 100%;
      height: 100%;
    }
  }

  &_btn {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    line-height: normal;
    border-radius: 40px;
    border: 4px solid #751813;
    background: rgba(122, 28, 15, 0.50);
    padding: 14px 30px;
    margin-top: 60px;
    @media screen and (max-width: 768px) {
      margin-top: 25px;
      font-size: 20px;
      padding: 10px 20px;
    }
  }
}

.contact {
  &_section {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & form {
      max-width: 780px;
      width: 100%;
      @media screen and (max-width: 1440px) {
        max-width: 750px;
      }
      @media screen and (max-width: 768px) {
        max-width: 640px;
      }
    }
  }

  &_title {
    color: #751813;
    text-align: center;
    font-size: 64px;
    font-weight: 900;
    line-height: normal;
    padding-bottom: 70px;
    padding-top: 20px;
    @media screen and (max-width: 1440px) {
      padding-bottom: 40px;
      font-size: 52px;
    }
    @media screen and (max-width: 768px) {
      padding-bottom: 30px;
      font-size: 34px;
    }
    @media screen and (max-width: 430px) {
      font-size: 30px;
      padding-bottom: 20px;
    }
  }

  &_inputs {
    max-width: 780px;
    width: 100%;
    @media screen and (max-width: 1440px) {
      max-width: 750px;
    }
    @media screen and (max-width: 768px) {
      max-width: 640px;
    }
  }

  &_dual {
    &-content {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 430px) {
        flex-direction: column;
        padding: 0 25px;
      }

      & .input {
        &_item {
          display: flex;
          flex-direction: column;
          padding-bottom: 30px;

          & label {
            color: #2A2C27;
            font-size: 24px;
            font-weight: 900;
            line-height: normal;
            @media screen and (max-width: 1440px) {
              font-size: 22px;
            }
            @media screen and (max-width: 768px) {
              font-size: 18px;
            }
          }

          & input {
            width: 360px;
            border: none;
            background: transparent;
            height: 40px;
            border-bottom: 1px solid #8D8D8D;
            @media screen and (max-width: 1440px) {
              height: 34px;
              width: 340px;
            }
            @media screen and (max-width: 768px) {
              width: 300px;
            }
            @media screen and (max-width: 430px) {
              width: 100%;
            }

            &:focus-visible {
              outline: none;
            }
          }
        }
      }
    }
  }

  &_checkbox {
    &-container {
      @media screen and (max-width: 430px) {
        padding: 0 25px;
      }
      & .check_label {
        color: #2A2C27;
        font-size: 24px;
        font-weight: 900;
        line-height: normal;
        padding-bottom: 20px;
        @media screen and (max-width: 1440px) {
          font-size: 22px;
        }
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      & .check_block {
        padding-bottom: 15px;
      }
    }
  }

  &_message {
    &-container {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      @media screen and (max-width: 430px) {
        padding: 0 25px 15px;
      }

      & label {
        color: #2A2C27;
        font-size: 24px;
        font-weight: 900;
        line-height: normal;
        padding-bottom: 10px;
        @media screen and (max-width: 1440px) {
          font-size: 22px;
        }
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      & textarea {
        background: transparent;
        border: none;
        height: 34px;
        border-bottom: 1px solid #8D8D8D;
        resize: none;
        &:focus-visible {
          outline: none;
        }
      }
    }
    &-btn {
      font-size: 24px;
      color: #fff;
      font-weight: bold;
      line-height: normal;
      border-radius: 40px;
      border: 4px solid #751813;
      background: rgba(122, 28, 15, 0.50);
      padding: 14px 30px;
      @media screen and (max-width: 768px) {
        font-size: 20px;
        padding: 10px 20px;
      }
      @media screen and (max-width: 430px) {
        font-size: 18px;
      }
      &_container {
        padding-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
