import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {auth: false}
  },
  {
    path: '/partner',
    name: 'partner',
    meta: {auth: false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PartnerView')
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    meta: {auth: false},
    component: () => import(/* webpackChunkName: "privacy_policy" */ '../views/PrivacyPolicy')
  },
  {
    path: '/cookie_policy',
    name: 'CookiePolicy',
    meta: {auth: false},
    component: () => import(/* webpackChunkName: "cookie_policy" */ '../views/CookiePolicy')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
