import { createStore } from 'vuex'
import axios from 'axios'
export default createStore({
  state: {
    baseURL: process.env.VUE_APP_BASE_URL,
    shouldScroll: false,
    scroll_element: ''
  },
  getters: {
  },
  mutations: {
    TRIGGER_SCROLL(state, data) {
      state.shouldScroll = true;  // Set the flag to true when scroll is triggered
      state.scroll_element = data
    },
    RESET_SCROLL(state) {
      state.shouldScroll = false;  // Reset the flag after scroll is done
    },
  },
  actions: {
    triggerScroll({ commit }, data) {
      commit('TRIGGER_SCROLL', data);
    },
    resetScroll({ commit }) {
      commit('RESET_SCROLL');
    },
    sendMail({state}, data) {
      return axios.post(`${state.baseURL}/send/email`, {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        message: data.message,
        hassle_free_storage: data.hassle_free_storage,
        ecommerce_solutions: data.ecommerce_solutions,
        general_information: data.general_information,
        storage_provider_collaborate: data.storage_provider_collaborate
      })
          .then((response) => {
            return response
          })
          .catch((error) => {
            return error
          })
    },

  },
  modules: {
  }
})
