import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'
import axios from "axios";
import 'vue-plyr/dist/vue-plyr.css'
import 'bootstrap/dist/css/bootstrap.css';
const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages: {
        en,
        es
    }
})
const app = createApp(App)
app.use(i18n)
app.use(axios)


createApp(App).use(i18n).use(store).use(router).mount('#app')
