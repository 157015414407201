<template>
  <div class="header_container">
    <div class="header_content">
      <div class="header_logo" @click="scrollTo('home_el')"></div>
      <div class="header_all-links">
        <ul class="header_links">
          <li class="header_link-item" @click="scrollTo('home_el')">{{$t('main_page.home')}}</li>
          <li class="header_link-item" @click="scrollTo('service_el')">{{$t('main_page.services')}}</li>
          <li class="header_link-item" @click="scrollTo('work_el')">{{$t('main_page.how_we_work')}}</li>
          <li class="header_link-item" @click="scrollTo('contact_el')">{{$t('main_page.contact_us')}}</li>
        </ul>
        <div class="lang_btn">
          <div class="dropdown">
            <div class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
              <div class="header_lang-flag" :class="{ 'spanish': $i18n.locale == 'es' }"></div>
            </div>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" @click.prevent="setLocale('en')" href="#"><span class="english"></span>{{$t('main_page.english')}}</a>
              <a class="dropdown-item" @click.prevent="setLocale('es')" href="#"><span class="spain"></span>{{$t('main_page.spanish')}}</a>
            </div>
          </div>
        </div>

        <div class="hamburger">
          <a class="main-nav-toggle" :class="{'active-menu': btn_status}" href="#main-nav" @click="changeBurger()"><i>Menu</i></a>
          <div class="menu_content" :class="{'active_menu': btn_status}">
            <ul class="header_links">
              <li class="header_link-item" @click="scrollTo('home_el')">{{$t('main_page.home')}}</li>
              <li class="header_link-item" @click="scrollTo('service_el')">{{$t('main_page.services')}}</li>
              <li class="header_link-item" @click="scrollTo('work_el')">{{$t('main_page.how_we_work')}}</li>
              <li class="header_link-item" @click="scrollTo('contact_el')">{{$t('main_page.contact_us')}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {mapActions} from "vuex";

export default {
  data() {
    return {
      language: localStorage.getItem('language') || "",
      btn_status: false
    }
  },
  created() {
    let lang = localStorage.getItem('language')
    if(lang) {
      this.language = lang
      this.$i18n.locale = lang
    } else  {
      this.language = 'es'
      this.$i18n.locale = 'es'
    }
  },
  methods: {
    ...mapActions(['triggerScroll']),
    scrollTo(el) {
      if(this.$route.fullPath !== '/') {
        this.$router.push('/')
      }
      setTimeout(() => {
        this.triggerScroll(el);
      }, 500)
    },
    setLocale(loc) {
      this.locale = loc
      this.$i18n.locale = loc
      localStorage.setItem('language', loc)
    },
    changeBurger() {
      this.btn_status = !this.btn_status
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  &_container {
    width: 100%;
    position: fixed;
    top: 0;
    height: 74px;
    display: flex;
    justify-content: center;
    z-index: 11;
    transition: .7s;
    background: #222724;
  }
  &_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 96px;
    @media screen and (max-width: 1680px) {
      padding: 0 70px;
    }
    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }
  &_logo {
    width: 165px;
    height: 64px;
    background: url("~@/assets/icons/header_logo.svg") no-repeat center;
    cursor: pointer;
  }
  &_all-links {
    display: flex;
    align-items: center;
    & .lang_btn {
      height: 27px;
      & .dropdown-menu {
        background: #222724;
      }
      & .dropdown-item {
        width: 150px;
        margin: 5px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        color: #fff;
        &:hover {
          background: #2d302d;
        }
        & .english {
          width: 40px;
          height: 27px;
          background: url("~@/assets/icons/us_flag.svg") no-repeat center;
          background-size: contain;
          margin-right: 15px;
        }
        & .spain {
          width: 40px;
          height: 27px;
          background: url("~@/assets/icons/spa_flag.svg") no-repeat center;
          background-size: contain;
          margin-right: 15px;
        }
      }
    }
    & .hamburger {
      height: 26px;
      top: 5px;
      margin-right: 27px;
      position: relative;
      display: none;
      @media screen and (max-width: 768px) {
        display: inline-block;
      }
      & .menu_content {
        position: absolute;
        top: 50px;
        right: -35px;
        background: #222724;
        border-radius: 8px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-200px);
        transition: .3s;
        &.active_menu {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
          transition: .3s;
        }
        & .header {
          &_links {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            align-items: flex-start;
            @media screen and (max-width: 768px) {
             width: 180px;
            }
          }
          &_link-item {
            width: 100%;
            padding: 10px 35px;
            border-bottom: 0.5px solid rgba(62, 61, 61, 0.65);
          }
        }
      }
      a.main-nav-toggle {
        display: block;
        width: 28px;
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;

        &:after,
        &:before {
          content: '';
          position: absolute;
          top: 0;
          height: 0;
          border-bottom: 3px solid #bbb;
          width: 100%;
          left: 0;
          right: 0;
          transition: all ease-out 0.3s;
        }

        &:after {
          top: 100%;
        }

        i {
          display: block;
          text-indent: 100%;
          overflow: hidden;
          white-space: nowrap;
          height: 3px;
          background-color: #bbb;
          width: 100%;
          position: absolute;
          top: 50%;
          transition: all ease-out 0.1s;
        }

        &.active-menu {
          &:after {
            transform: rotate(-45deg);
            transform-origin: center;
            top: 50%;
          }

          &:before {
            transform: rotate(45deg);
            transform-origin: center;
            top: 50%
          }

          i {
            opacity: 0;
          }
        }
      }
    }
  }
  &_links {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &_link {
    &-item {
      list-style-type: none;
      color: #fff;
      padding: 0 20px;
      font-size: 20px;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        padding: 0 10px;
      }
    }
  }
  &_lang {
    &-flag {
      width: 40px;
      height: 27px;
      background: url("~@/assets/icons/us_flag.svg") no-repeat center;
      background-size: contain;
      margin: 0 20px;
      &.spanish {
        background: url("~@/assets/icons/spa_flag.svg") no-repeat center;
      }
    }
  }

}
</style>
