export default {
  "main_page": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "how_we_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "first_logistics_storage_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plataforma #1 de España de almacenamiento logístico."])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta Ahora"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacena"])},
    "deliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
    "join_spains_leading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Únete a la plataforma #1 de almacenamiento logístico en España y encuentra tu espacio ideal!"])},
    "logistic_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Logístico"])},
    "e_commerce_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logística E-Commerce"])},
    "customs_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento  Aduanero"])},
    "OPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones 24/7"])},
    "ADR_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento de Materiales Peligrosos (ADR)"])},
    "cold_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento en Frío"])},
    "frozen_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Congelado"])},
    "pharma_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Farmacéutico"])},
    "many_more_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+MÁS"])},
    "join_and_discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete Ahora"])},
    "keep_track_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue el ritmo de tu negocio:"])},
    "store_and_deliver_effortlessly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["almacena y entrega sin complicaciones"])},
    "find_space_and_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra espacio para almacenar en un clic, mantén control de tu inventario y entregas en un solo sitio."])},
    "connect_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta Ahora"])},
    "our_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros clientes nos "])},
    "review_from_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews de clientes"])},
    "carlos_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trakilos nos ha permitido simplificar el proceso de logística. Tras integrar nuestra tienda online con su sistema solo nos concentramos en crecer. Ser capaces de seguir cada venta y elegir donde almacenamos para reducir costes y simplificar la logística ha sido la clave para nuestro éxito"])},
    "javier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar espacio de almacenamiento durante el peak era complicado hasta que comenzamos a trabajar con Trakilos. Ahora podemos almacenar fácilmente parte de nuestro inventario antes de enviarlo a otras ciudades. Saber que podemos encontrar espacio en cualquier momento sin tener que esperar para tener respuesta, ha hecho que nuestros envíos nacionales e internacionales sean mucho más sencillos."])},
    "laura_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tener todo en un solo lugar es genial. Antes, gestionábamos nuestro comercio electrónico, almacenamiento, entregas, seguimiento y facturación en diferentes plataformas, lo que dificultaba que nuestro equipo estuviera al tanto. Incluso perdimos ventas debido a retrasos. Ahora que todo está consolidado, podemos supervisar fácilmente nuestras ventas, rastrear las entregas y estar al día sobre nuestro stock y el estado de las entregas."])},
    "cities_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudades a Las Que Servimos Actualmente"])},
    "cities_we_are_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudades donde llegamos"])},
    "barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
    "madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
    "seville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sevilla"])},
    "valencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valencia"])},
    "saragossa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaragoza"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximamente..."])},
    "streamline_your_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡ Simplifica tu logística con una solución segura, transparente e innovadora !"])},
    "e_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce/Soluciones Personalizadas B2C"])},
    "b2b_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluciones Personalizades B2B"])},
    "connect_with_our_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecta con nuestra platforma"])},
    "choose_your_best_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu espacio"])},
    "start_growing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empieza a crecer"])},
    "tell_us_about_your_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Háblanos acerca de tu espacio"])},
    "complete_the_KYC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellena el KYC"])},
    "storage_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacena inmediatamente"])},
    "keep_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento 24/7"])},
    "pack_&_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogidas, Empaques y Envios"])},
    "deliver_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas"])},
    "deliver_box_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega con nosotros (opcional)"])},
    "stock_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve tu inventario cuando quieras"])},
    "keep_your_logistics_moving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantén tu logística en movimiento,"])},
    "simple_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integración simple y seguimiento ilimitado 24/7"])},
    "join_spains_top_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a la plataforma líder en almacenamiento y entrega para empresas en España. Simplificamos la logística conectando tus necesidades con soluciones, todo en un mismo lugar."])},
    "why_trakilos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué Trakilos?"])},
    "discover_new_growth_opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre nuevas oportunidades de crecimiento."])},
    "carousel_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Te resulta increíble? Piensa en lo fácil que es pedir comida con UberEats: ¡pides, pagas y listo! Ahora imagina esa misma comodidad para tu negocio. Inicia sesión en tu cuenta, integra nuestra plataforma y observa cómo hacemos de tu logística algo sencillo y eficiente. ¡Hagamos que gestionar tu negocio sea tan fácil como pedir la cena!"])},
    "get_started_in_just_3_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empieza en solo 3 pasos:"])},
    "are_you_a_storage_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes un almacén?"])},
    "parnet_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colabora con nosotros"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "what_are_you_looking_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué estás buscando?"])},
    "hassle_free_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Fácil"])},
    "e_commerce_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluciones E-Commerce"])},
    "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información General"])},
    "im_a_storage_provider_eager_to_collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy proveedor de almacenamiento interesado en colaborar"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
    "type_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu mensaje"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
  },
  "carousel_text": {
    "immediate_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISPONIBILIDAD INMEDIATA"])},
    "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIMPLE"])},
    "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGURO"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRECE"])},
    "immediate_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Así de fácil! Espacios de almacenamiento y soluciones para e-commerce disponibles AHORA MISMO.  "])},
    "immediate_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di adiós a las esperas y a los correos interminables. Con TRAKILOS, es muy sencillo: regístrate, conéctate y comienza a almacenar ¡hoy mismo!"])},
    "simple_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Te resulta increíble? Piensa en lo fácil que es pedir comida con UberEats: ¡pides, pagas y listo! "])},
    "simple_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora imagina esa misma comodidad para tu negocio. Inicia sesión en tu cuenta, integra nuestra plataforma y observa cómo hacemos de tu logística algo sencillo y eficiente. "])},
    "simple_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hagamos que gestionar tu negocio sea tan fácil como pedir la cena!"])},
    "safe_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu tranquilidad es lo primero! Tu inventario es importante para ti y por eso también lo es para nosotros. "])},
    "safe_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con nuestras soluciones de almacenamiento, gestiona tu logística con total confianza, sabiendo que tus productos están siempre en manos seguras y en espacios que cumplen con nuestros rigurosos estándares de calidad"])},
    "grow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deja la logística en manos de los expertos y concéntrate en lo que realmente es importante para ti: "])},
    "grow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUMENTA tus ventas y haz CRECER tu empresa."])}
  },
  "partner_carousel_text": {
    "discover_new_growth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre nuevas oportunidades de crecimiento."])},
    "reduce_the_risk_of_non-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disminuye el riesgo de impago"])},
    "all_in_one_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo en un solo lugar"])},
    "increase_your_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumenta tu visibilidad: "])},
    "discover_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muchas empresas están buscando soluciones de almacenamiento o entrega en tu zona. "])},
    "discover_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al colaborar con Trakilos y añadir tu almacenamiento a nuestra plataforma, te conectamos con esos clientes potenciales."])},
    "reduce_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con nuestra plataforma, tus pagos están seguros. Analizamos el perfil del cliente con datos de ASNEF para garantizar transacciones confiables."])},
    "one_place_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvídate de correos interminables y documentos perdidos. "])},
    "one_place_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con nuestra plataforma, tienes todo el inventario, envíos, entregas y facturas de tus clientes en un solo lugar. Así, gestionas todo más fácil y te centras en lo que realmente importa."])},
    "visibility_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumenta tu visibilidad con marketing dentro de la aplicación para llegar a más clientes y hacer crecer tu negocio."])}
  },
  "footer": {
    "reach_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta con Nosotros"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
    "cookies_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cookies"])},
    "terms_&_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
    "all_rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los Derechos Reservados. "])}
  },
  "privacy_policy": {
    "privacy_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PYRAMID LOGISTICS SL, en adelante TRAKILOS, garantiza la adopción de las medidas técnicas y organizativas de seguridad necesarias para gestionar los datos del usuario con carácter personal en conformidad con lo que se establece en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD); la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD); el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (RDLOPD) y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE). "])},
    "privacy_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De este modo, los datos personales facilitados por el usuario, así como su correo electrónico, serán añadidos y tratados en los ficheros de TRAKILOS, (titular de la web https://trakilos.com), con el fin único de facilitar y brindar servicios, atender consultas o solicitudes, mejorar la calidad, funcionamiento y navegación del Sitio Web, realizar campañas comerciales de su posible interés (ofertas, promociones, descuentos, etc.) y/o realizar estudios con fines estadísticos."])},
    "privacy_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos personales del usuario serán tratados bajo la más estricta confidencialidad, aplicando las medidas de seguridad técnicas y organizativas que razonablemente están al alcance según el nivel de seguridad adecuado al riesgo de los datos recogidos y de conformidad con la normativa vigente.  "])},
    "privacy_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base legal para el tratamiento de los datos personales es el consentimiento, por lo que TRAKILOS, se compromete a recabar el consentimiento expreso y verificable del usuario para el tratamiento de sus datos personales para uno o varios fines específicos, encontrándose el usuario facultado para retirar su consentimiento en cualquier momento, cuando el mismo se haya otorgado para el tratamiento de sus datos. En ningún caso, la retirada de ese consentimiento condicionará la ejecución del contrato de suscripción o las relaciones generadas con anterioridad.  "])},
    "privacy_text_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el usuario no desea recibir esta información comercial puede comunicarlo mediante correo electrónico a la casilla dpotrakilos.com y será dado de baja como destinatario de este tipo de información."])},
    "privacy_text_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asimismo, podrá ejercitar en todo momento los siguientes derechos reconocidos en el RGPD y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales: Solicitar el acceso a sus datos personales o su rectificación cuando sean inexactos; solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesario para los fines para los que fueron recogidos; solicitar la limitación y/o oposición del tratamiento de sus datos en determinadas circunstancias; solicitar la portabilidad de los datos en los casos previstos en la normativa, así como otros derechos allí reconocidos."])},
    "privacy_text_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fin de solicitar el acceso, rectificación, supresión, limitación del tratamiento, portabilidad y oposición, de los datos personales, el usuario deberá enviar un correo electrónico a la casilla dpotrakilos.com en el cual deberá especificar el derecho que quiere ejercer y respecto a qué datos personales y, asimismo, indicar su nombre, apellido, DNI y domicilio a efectos de futuras notificaciones y acreditar su identidad con copia de un documento oficial de identificación que lo identifique legalmente. "])},
    "privacy_text_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de divergencias con TRAKILOS en relación con el tratamiento de los datos, el usuario podrá presentar una reclamación ante la Agencia Española de Protección de Datos (https://www.aepd.es/)."])},
    "privacy_text_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las comunicaciones privadas entre TRAKILOS y sus usuarios o compradores serán consideradas confidenciales y en ningún caso se transferirán a terceros, salvo en la medida en que así lo requiera la prestación de los servicios contratados. En el mismo sentido, en caso de que los datos sean requeridos por vía legal, administrativa o judicial, la Empresa se verá obligada a revelar los mismos, pero siempre en la medida en que la legislación y normas de procedimiento lo permitan. "])},
    "privacy_text_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos personales recolectados son de carácter meramente identificativo. Los mismos serán resguardados el tiempo que sea necesario para que TRAKILOS pueda cumplir con sus obligaciones contractuales, legales o reglamentarias durante los plazos aplicables en la normativa vigente."])},
    "privacy_text_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAKILOS se reserva el derecho a modificar esta política de acuerdo con su propio criterio, o motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos, así como a prácticas de la industria. "])},
    "privacy_text_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Además, esta Política de Privacidad puede variar en función de exigencias legislativas o de autorregulación, por lo que se aconseja su visita periódica para estar al tanto de los últimos cambios o actualizaciones. "])},
    "privacy_text_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios o actualizaciones de esta Política de Privacidad no serán notificados al usuario de forma explícita. "])},
    "privacy_text_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A los efectos antes detallados, el responsable del tratamiento de los datos personales es TRAKILOS - PYRAMID LOGISTICS SL:"])},
    "privacy_text_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio: Av Olof Palme 10, Bj, 08840, Viladecans, Barcelona"])},
    "privacy_text_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico: dpotrakilos.com "])},
    "privacy_text_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIF B72448764"])}
  },
  "cookie_policy": {
    "cookie_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El acceso a la página web https://trakilos.com/ puede implicar la utilización de cookies. Las cookies son procedimientos automáticos de recogida de información que permiten al usuario identificarse de forma automática y que facilita a PYRAMID LOGISTICS SL, en adelante TRAKILOS, la obtención de información sobre los hábitos y comportamientos de sus usuarios dentro de su web para así poder mejorarlo, personalizar la experiencia del usuario y facilitar la navegación y usabilidad."])},
    "cookie_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin perjuicio de que las cookies recogen información mediante procedimientos automáticos, las mismas no permiten contactarse con el número de teléfono de un usuario o con cualquier otro medio de contacto personal. Tampoco pueden extraer información del disco duro o robar información personal. La única manera de que la información privada del usuario forme parte del archivo Cookie es que el usuario dé personalmente esa información al servidor."])},
    "cookie_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Según la entidad que gestione el dominio desde dónde se envían las cookies y se traten los datos que se obtengan, se pueden distinguir dos tipos: cookies propias (gestionadas exclusivamente por TRAKILOS) y cookies de terceros (gestionadas por entidades externas que proporcionan a TRAKILOS servicios solicitados para mejorar el sitio web y la experiencia de usuario)."])},
    "cookie_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de cookies de sesión (recaban datos mientras el usuario navega por la red) o cookies persistentes (se almacenan en el terminal y la información obtenida se utiliza por el responsable de la cookie con la finalidad de prestar el servicio solicitado)."])},
    "cookie_text_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos: cookies técnicas, cookies de personalización, cookies de análisis, cookies publicitarias y cookies de publicidad comportamental."])},
    "cookie_text_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para más información a este respecto puede consultar la Guía sobre el uso de las cookies de la Agencia Española de Protección de Datos."])},
    "cookie_text_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les será de aplicación la Política de Privacidad (disponible en el sitio web). En este sentido, para la utilización de las mismas será necesario el consentimiento del usuario. Este consentimiento será comunicado, en base a una elección auténtica, ofrecido mediante una decisión afirmativa y positiva, antes del tratamiento inicial, removible y documentado."])},
    "cookie_text_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que el usuario decida no autorizar su uso, TRAKILOS no almacenaría ninguna cookie y tan sólo se usarían las cookies técnicas dado que sin ellas no es posible navegar por la web. En caso de seguir navegando por la web y no denegar la autorización, dicha actividad implicará la aceptación de su uso. El usuario podrá deshabilitar, rechazar y eliminar las cookies —total o parcialmente— instaladas en su dispositivo mediante la configuración de su navegador de internet. En cada navegador la operativa es diferente, a continuación, se facilitan los enlaces de distintos navegadores sobre cómo realizar dichas modificaciones:"])},
    "cookie_text_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que el usuario realice modificaciones en la configuración de las cookies, TRAKILOS informa que no asegura el correcto funcionamiento del sitio web, pudiendo verse limitada la utilización de ciertas prestaciones del mismo."])},
    "cookie_text_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que es una Cookies"])},
    "cookie_text_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin cookies los servicios ofrecidos por cualquier página se verían mermados notablemente. Si desea consultar más información sobre qué son las cookies, qué almacenan, cómo eliminarlas, desactivarlas, etc., por favor, continúe leyendo."])},
    "cookie_text_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies utilizadas en este sitio web"])},
    "cookie_text_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de cookies que hace esta web con el fin de informarle con la máxima exactitud posible."])},
    "cookie_text_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web utiliza las siguientes cookies propias:"])},
    "cookie_text_15_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de sesión, para garantizar que los usuarios que escriban comentarios en el blog sean humanos y no aplicaciones De esta forma se combate el spam."])},
    "cookie_text_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web utiliza las siguientes cookies de terceros:"])},
    "cookie_text_17_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google."])},
    "cookie_text_17_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones del tipo Me gusta o Compartir."])},
    "cookie_text_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivación o eliminación de cookies"])},
    "cookie_text_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web. Estas acciones se realizan de forma diferente en función del navegador que esté usando. Pasar saber más acerca de los navegadores, por favor, continue leyendo."])},
    "cookie_text_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas adicionales"])},
    "cookie_text_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies."])},
    "cookie_text_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados"])},
    "cookie_text_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las"])},
    "cookie_text_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Puede consultar información detallada a este respecto en este enlace. Si desea información sobre el uso que Google da a las cookies le adjuntamos este otro enlace. Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través de la sección de"])},
    "cookie_text_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es una cookie?"])},
    "cookie_text_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una cookie es un fichero de texto inofensivo que se almacena en su navegador cuando visita casi cualquier página web. La utilidad de la cookie es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Aunque mucha gente no lo sabe las cookies se llevan utilizando desde hace 20 años, cuando aparecieron los primeros navegadores para la World Wide Web."])},
    "cookie_text_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué NO ES una cookie?"])},
    "cookie_text_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es un virus, ni un troyano, ni un gusano, ni spam, ni spyware, ni abre ventanas pop-up."])},
    "cookie_text_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué información almacena una cookie?"])},
    "cookie_text_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies no suelen almacenar información sensible sobre usted, como tarjetas de crédito o datos bancarios, fotografías, su DNI o información personal, etc. Los datos que guardan son de carácter técnico, preferencias personales, personalización de contenidos, etc. El servidor web no le asocia a usted como persona si no a su navegador web. De hecho, si usted navega habitualmente con Internet Explorer y prueba a navegar por la misma web con Firefox o Chrome verá que la web no se da cuenta que es usted la misma persona porque en realidad está asociando al navegador, no a la persona."])},
    "cookie_text_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué tipo de cookies existen?"])},
    "cookie_text_32_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies técnicas: Son las más elementales y permiten, entre otras cosas, saber cuándo está navegando un humano o una aplicación automatizada, cuándo navega un usuario anónimo y uno registrado, tareas básicas para el funcionamiento de cualquier web dinámica."])},
    "cookie_text_32_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de análisis: Recogen información sobre el tipo de navegación que está realizando, las secciones que más utiliza, productos consultados, franja horaria de uso, idioma,"])},
    "cookie_text_32_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies publicitarias: Muestran publicidad en función de su navegación, su país de procedencia, idioma,"])},
    "cookie_text_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué son las cookies propias y las de terceros?"])},
    "cookie_text_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies propias son las generadas por la página que está visitando y las de terceros son las generadas por servicios o proveedores externos como Facebook, Twitter, Google, etc."])},
    "cookie_text_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué ocurre si desactivo las cookies?"])},
    "cookie_text_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que entienda el alcance que puede tener desactivar las cookies le mostramos unos ejemplos:"])},
    "cookie_text_37_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No podrá compartir contenidos de esa web en Facebook, Twitter o cualquier otra red"])},
    "cookie_text_37_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio web no podrá adaptar los contenidos a sus preferencias personales, como suele ocurrir en las tiendas"])},
    "cookie_text_37_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No podrá acceder al área personal de esa web, como por ejemplo Mi cuenta, o Mi perfil o Mis pedidos."])},
    "cookie_text_37_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiendas online: Le será imposible realizar compras online, tendrán que ser telefónicas o visitando la tienda física si es que dispone de"])},
    "cookie_text_37_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No será posible personalizar sus preferencias geográficas como franja horaria, divisa o"])},
    "cookie_text_37_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio web no podrá realizar analíticas web sobre visitantes y tráfico en la web, lo que dificultará que la web sea"])},
    "cookie_text_37_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No podrá escribir en el blog, no podrá subir fotos, publicar comentarios, valorar o puntuar La web tampoco podrá saber si usted es un humano o una aplicación automatizada que publica spam."])},
    "cookie_text_37_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se podrá mostrar publicidad sectorizada, lo que reducirá los ingresos publicitarios de la"])},
    "cookie_text_37_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las redes sociales usan cookies, si las desactiva no podrá utilizar ninguna red"])},
    "cookie_text_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se pueden eliminar las cookies?"])},
    "cookie_text_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí. No sólo eliminar, también bloquear, de forma general o particular para un dominio específico. Para eliminar las cookies de un sitio web debe ir a la configuración de su navegador y allí podrá buscar las asociadas al dominio en cuestión y proceder a su eliminación."])},
    "cookie_text_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de cookies para los navegadores más polulares"])},
    "cookie_text_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A continuación le indicamos cómo acceder a una cookie determinada del navegador Chrome. Nota: estos pasos pueden variar en función de la versión del navegador:"])},
    "cookie_text_42_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Configuración o Preferencias mediante el menú Archivo o bien pinchando el icono de personalización que aparece arriba a la"])},
    "cookie_text_42_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verá diferentes secciones, pinche la opción Mostrar opciones avanzadas."])},
    "cookie_text_42_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Privacidad, Configuración de contenido."])},
    "cookie_text_42_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Todas las cookies y los datos de sitios."])},
    "cookie_text_42_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aparecerá un listado con todas las cookies ordenadas por dominio. Para que le sea más fácil encontrar las cookies de un determinado dominio introduzca parcial o totalmente la dirección en el campo Buscar cookies."])},
    "cookie_text_42_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tras realizar este filtro aparecerán en pantalla una o varias líneas con las cookies de la web solicitada. Ahora sólo tiene que seleccionarla y pulsar la X para proceder a su eliminación."])},
    "cookie_text_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la configuración de cookies del navegador Internet Explorer siga estos pasos (pueden variar en función de la versión del navegador):"])},
    "cookie_text_44_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Herramientas, Opciones de Internet"])},
    "cookie_text_44_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga click en Privacidad."])},
    "cookie_text_44_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueva el deslizador hasta ajustar el nivel de privacidad que"])},
    "cookie_text_45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la configuración de cookies del navegador Firefox siga estos pasos (pueden variar en función de la versión del navegador):"])},
    "cookie_text_46_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Opciones o Preferencias según su sistema"])},
    "cookie_text_46_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga click en Privacidad."])},
    "cookie_text_46_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Historial elija Usar una configuración personalizada para el historial."])},
    "cookie_text_46_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora verá la opción Aceptar cookies, puede activarla o desactivarla según sus"])},
    "cookie_text_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la configuración de cookies del navegador Safari para OSX siga estos pasos (pueden variar en función de la versión del navegador):"])},
    "cookie_text_48_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Preferencias, luego Privacidad."])},
    "cookie_text_48_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este lugar verá la opción Bloquear cookies para que ajuste el tipo de bloqueo que desea"])},
    "cookie_text_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la configuración de cookies del navegador Safari para iOS siga estos pasos (pueden variar en función de la versión del navegador):"])},
    "cookie_text_50_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Ajustes, luego Safari."])},
    "cookie_text_50_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Privacidad y Seguridad, verá la opción Bloquear cookies para que ajuste el tipo de bloqueo que desea"])},
    "cookie_text_51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la configuración de cookies del navegador para dispositivos Android siga estos pasos (pueden variar en función de la versión del navegador):"])},
    "cookie_text_52_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecute el navegador y pulse la tecla Menú, luego Ajustes."])},
    "cookie_text_52_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Seguridad y Privacidad, verá la opción Aceptar cookies para que active o desactive la"])},
    "cookie_text_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la configuración de cookies del navegador para dispositivos Windows Phone siga estos pasos (pueden variar en función de la versión del navegador):"])},
    "cookie_text_54_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abra Internet Explorer, luego Más, luego Configuración"])},
    "cookie_text_54_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puede activar o desactivar la casilla Permitir cookies."])}
  },
  "partner_page": {
    "become_a_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colabora con nosotros"])},
    "partner_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información para socios"])},
    "account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre la cuenta"])},
    "storage_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirreción del Almacén"])},
    "number_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número/Detalles"])},
    "storage_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Fiscal"])},
    "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Comercial"])},
    "VAT_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIF"])},
    "surface_in_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie en m²"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "certifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificaciones"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Al hacer click en “Enviar”, acepto los Términos y Condiciones y la Política de Privacidad."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
  }
}