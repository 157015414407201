export default {
  "main_page": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "how_we_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we work"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "first_logistics_storage_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The #1 Logistics Storage Platform in Spain!"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "deliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver"])},
    "join_spains_leading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Spain's leading logistics storage platform and find your ideal space solution!"])},
    "logistic_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistic Storage"])},
    "e_commerce_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce Logistics"])},
    "customs_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customs Warehouse"])},
    "OPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 OPS"])},
    "ADR_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADR Storage"])},
    "cold_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Storage"])},
    "frozen_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen Storage"])},
    "pharma_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharma Storage"])},
    "many_more_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Many more services instead of the"])},
    "join_and_discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join and Discover"])},
    "keep_track_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track of your business:"])},
    "store_and_deliver_effortlessly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store and deliver effortlessly with Trakilos"])},
    "find_space_and_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find space and storage in just a click, keep tabs on your stock and deliveries all in one place."])},
    "connect_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Now"])},
    "our_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our clients "])},
    "review_from_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review from clients"])},
    "carlos_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrating our e-commerce store with TRAKILOS system has allowed us to focus on expanding our business. Being able to track every sale and choose the best storage locations has really helped us reduce shipping costs and improve logistics."])},
    "javier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding storage during peak season was tough until we partnered with Trakilos. Now, we can easily store part of our inventory before shipping it to other cities. Knowing we can find space anytime, without long wait times for answers, has made our national and international shipping so much easier."])},
    "laura_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having everything in one place is fantastic. Before, we managed our e-commerce, storage, deliveries, tracking and billing on different platforms, which made it hard for our team to keep track. We even lost sales because of delays. Now that everything is consolidated, we can easily monitor our sales, track deliveries, and stay updated on our stock and delivery statuses. "])},
    "cities_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities We Are Currently Serving"])},
    "cities_we_are_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities We Are Currently Serving"])},
    "barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
    "madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
    "seville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seville"])},
    "valencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valencia"])},
    "saragossa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saragossa"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon..."])},
    "streamline_your_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamline your logistics with secure, transparent, and innovative solutions!"])},
    "e_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce / B2C Tailored Solutions"])},
    "b2b_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B Tailored Solutions"])},
    "connect_with_our_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with our platform"])},
    "choose_your_best_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your best warehouse"])},
    "start_growing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start growing"])},
    "tell_us_about_your_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about your storage"])},
    "complete_the_KYC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the KYC"])},
    "storage_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage Immediately"])},
    "keep_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track 24/7"])},
    "pack_&_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick, Pack & Ship"])},
    "deliver_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver"])},
    "deliver_box_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver with us (optional)"])},
    "stock_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your stock anytime"])},
    "keep_your_logistics_moving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep your logistics moving with"])},
    "simple_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple integration and unlimited tracking 24/7"])},
    "join_spains_top_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Spain's top storage and delivery platform for businesses, where we streamline logistics by connecting NEEDS with SOLUTIONS - all in one place."])},
    "why_trakilos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Trakilos ?"])},
    "discover_new_growth_opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover new growth opportunities"])},
    "carousel_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think it sounds too good to be true? Remember how easy UberEats made ordering food? Order, pay, and enjoy, right? Now, imagine that simplicity for your business. Just log into your dashboard, integrate our platform, and watch us simplify your logistics. Let’s make running your business as easy as ordering dinner!"])},
    "get_started_in_just_3_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started in just 3 steps:"])},
    "are_you_a_storage_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you a storage provider ?"])},
    "parnet_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner with us"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "what_are_you_looking_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you looking for?"])},
    "hassle_free_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hassle-Free Storage"])},
    "e_commerce_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce Solutions"])},
    "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
    "im_a_storage_provider_eager_to_collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m a storage provider eager to collaborate."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "type_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type your message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "carousel_text": {
    "immediate_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMMEDIATE AVAILABILITY"])},
    "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIMPLE"])},
    "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAFE"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROW"])},
    "immediate_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just like that! Storage space and e-commerce solutions available RIGHT NOW. "])},
    "immediate_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say goodbye to waiting for availableness and endless email threads. With TRAKILOS, it’s simple: register, connect, and start storing today!"])},
    "simple_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think it sounds too good to be true? Remember how easy UberEats made ordering food? "])},
    "simple_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order, pay, and enjoy, right? Now, imagine that simplicity for your business. Just log into your dashboard, integrate our platform, and watch us simplify your logistics. "])},
    "simple_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s make running your business as easy as ordering dinner!"])},
    "safe_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ve got your back covered! Your inventory matters, and that’s why it matters to us too. "])},
    "safe_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our storage solutions, you can manage your logistics confidently, knowing your products are only stored and handled in spaces that pass our verification process. "])},
    "grow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave the logistics to the experts and focus on what’s really important for you: "])},
    "grow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INCREASING your sales and GROWING your company."])}
  },
  "partner_carousel_text": {
    "discover_new_growth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover new growth opportunities."])},
    "reduce_the_risk_of_non-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce the risk of non-payment."])},
    "all_in_one_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All in One Place"])},
    "increase_your_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase your visibility."])},
    "discover_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerous businesses are searching for storage or delivery solutions in your area. "])},
    "discover_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By partnering with Trakilos and listing your storage on our platform, we can connect you with those potential clients."])},
    "reduce_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our platform, your payments are secure. We analyze customer profiles using ASNEF data to ensure reliable transactions."])},
    "one_place_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say goodbye to lengthy email chains, misplaced documents, and scattered information."])},
    "one_place_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our platform centralizes all your clients' inventory, shipping, delivery, and billing details in one place, simplifying management and letting you focus on what’s really important."])},
    "visibility_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your presence with in-app marketing to reach more clients and grow your business."])}
  },
  "footer": {
    "reach_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach us"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "cookies_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies Policy"])},
    "terms_&_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "all_rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" All rights reserved."])}
  },
  "privacy_policy": {
    "privacy_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PYRAMID LOGISTICS SL, hereinafter TRAKILOS, guarantees the adoption of the necessary technical and  organizational security measures to manage users' personal data in accordance with the provisions established in  Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the   protection of natural persons concerning the processing of personal data and the free movement of such data (GDPR); Organic Law 3/2018, of December 5, on Personal Data Protection and guarantee of digital rights  (LOPD-GDD); Royal Decree 1720/2007, of December 21, which approves the Regulation for the development of Organic\n        Law 15/1999, of December 13, on Personal Data Protection (RDLOPD); and Law 34/2002, of July 11, on Information Society Services and Electronic Commerce (LSSI-CE)."])},
    "privacy_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thus, the personal data provided by the user, as well as their email address, will be added to and processed in TRAKILOS's files (holder of the website https://trakilos.com), for the sole purpose of providing and offering   services, addressing inquiries or requests, improving the quality, functioning, and navigation of the Website,    carrying out commercial campaigns of potential interest (offers, promotions, discounts, etc.), and/or conducting studies for statistical purposes."])},
    "privacy_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user's personal data will be processed under the strictest confidentiality, applying the security measures  that are technically and organizationally reasonable according to the level of security appropriate to the risk  of the collected data and in accordance with current regulations."])},
    "privacy_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The legal basis for processing personal data is consent; therefore, TRAKILOS commits to obtaining the express  and verifiable consent of the user for the processing of their personal data for one or several specific  purposes, with the user entitled to withdraw their consent at any time when it has been granted for the   processing of their data. In no case will the withdrawal of consent affect the execution of the subscription   contract or any relationships generated prior to its withdrawal."])},
    "privacy_text_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the user does not wish to receive this commercial information, they can communicate it via email to  dpotrakilos.com and will be unsubscribed as a recipient of this type of information."])},
    "privacy_text_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionally, they may exercise at any time the following rights recognized in the GDPR and Organic Law 3/2018,  of December 5, on Personal Data Protection and guarantee of digital rights: request access to their personal  data or their rectification when inaccurate; request its deletion when, among other reasons, the data is no  longer necessary for the purposes for which it was collected; request the limitation and/or opposition to the  processing of their data under certain circumstances; request the portability of the data in cases provided by  regulations, as well as other rights recognized therein."])},
    "privacy_text_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to request access, rectification, deletion, limitation of processing, portability, and opposition to  personal data, the user must send an email to dpotrakilos.com specifying the right they wish to exercise and  regarding which personal data, and also provide their name, surname, ID number, and address for future  notifications, as well as prove their identity with a copy of an official identification document."])},
    "privacy_text_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of discrepancies with TRAKILOS regarding the processing of data, the user may file a complaint with the  Spanish Data Protection Agency (https://www.aepd.es/)."])},
    "privacy_text_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All private communications between TRAKILOS and its users or buyers will be considered confidential and will not  be transferred to third parties, except as required for the provision of contracted services. Similarly, if data  is legally, administratively, or judicially required, the Company will be obliged to disclose it, but only to   the extent permitted by legislation and procedural rules."])},
    "privacy_text_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collected personal data is strictly identifying in nature. They will be safeguarded for as long as necessary  for TRAKILOS to fulfill its contractual, legal, or regulatory obligations during the applicable periods established in current regulations."])},
    "privacy_text_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAKILOS reserves the right to modify this policy at its discretion or due to legislative, jurisprudential, or  doctrinal changes from the Spanish Data Protection Agency, as well as to industry practices."])},
    "privacy_text_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moreover, this Privacy Policy may vary depending on legislative or self-regulatory requirements; therefore,  periodic visits are recommended to stay informed of the latest changes or updates."])},
    "privacy_text_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes or updates to this Privacy Policy will not be explicitly notified to the user."])},
    "privacy_text_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the purposes outlined above, the person responsible for the processing of personal data is TRAKILOS -  PYRAMID LOGISTICS SL:  "])},
    "privacy_text_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address: Av Olof Palme 10, Bj, 08840, Viladecans, Barcelona "])},
    "privacy_text_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: dpotrakilos.com  "])},
    "privacy_text_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT  B72448764"])}
  },
  "cookie_policy": {
    "cookie_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessing the website https://trakilos.com/ may involve the use of cookies. Cookies are automatic information collection procedures that allow the user to identify themselves automatically and facilitate PYRAMID LOGISTICS SL, hereinafter TRAKILOS, in obtaining information about their users' habits and behaviors within their website to improve it, personalize the user experience, and facilitate navigation and usability."])},
    "cookie_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While cookies collect information through automatic procedures, they do not allow contact with the user's phone number or any other personal contact means. They also cannot extract information from the hard drive or steal personal information. The only way for the user's private information to be part of the Cookie file is if the user personally provides that information to the server."])},
    "cookie_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the entity that manages the domain from which cookies are sent and the data that is processed, two types can be distinguished: first-party cookies (managed exclusively by TRAKILOS) and third-party cookies (managed by external entities that provide TRAKILOS with requested services to improve the website and user experience)."])},
    "cookie_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is also a second classification based on the time period they remain stored in the user's browser; they can be session cookies (which collect data while the user browses) or persistent cookies (which are stored on the device and the information collected is used by the cookie controller to provide the requested service)."])},
    "cookie_text_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastly, there is another classification with five types of cookies depending on the purpose for which the obtained data is processed: technical cookies, personalization cookies, analytical cookies, advertising cookies, and behavioral advertising cookies."])},
    "cookie_text_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information in this regard, you can consult the Cookie Use Guide from the Spanish Data Protection Agency."])},
    "cookie_text_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies that allow the identification of a person are considered personal data. Therefore, the Privacy Policy will apply to them (available on the website). In this sense, the use of them will require user consent. This consent will be communicated, based on an authentic choice, offered through an affirmative and positive decision, before the initial processing, removable and documented."])},
    "cookie_text_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the user decides not to authorize their use, TRAKILOS will not store any cookies, and only technical cookies will be used since it is not possible to navigate the website without them. If the user continues browsing the website and does not deny authorization, such activity will imply acceptance of their use. The user can disable, reject, and delete cookies—totally or partially—installed on their device through their internet browser settings. The operation varies across browsers; below are links for different browsers on how to make these modifications:"])},
    "cookie_text_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the user makes modifications to cookie settings, TRAKILOS informs that it does not guarantee the correct functioning of the website, and certain functionalities may be limited."])},
    "cookie_text_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is a Cookie?"])},
    "cookie_text_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cookie is a small text file that is stored in your browser when you visit almost any webpage. Its utility is that the website is able to remember your visit when you navigate that page again. Cookies usually store technical information, personal preferences, content personalization, usage statistics, links to social networks, user account access, etc. The goal of a cookie is to adapt the website content to your profile and needs; without cookies, the services offered by any page would be significantly diminished. If you want more information about what cookies are, what they store, how to delete them, deactivate them, etc., please continue reading."])},
    "cookie_text_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies Used on This Website"])},
    "cookie_text_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following the guidelines of the Spanish Data Protection Agency, we proceed to detail the use of cookies made by this website to inform you as accurately as possible."])},
    "cookie_text_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses the following first-party cookies:"])},
    "cookie_text_15_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session cookies, to ensure that users writing comments on the blog are human and not automated applications. This helps combat spam."])},
    "cookie_text_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses the following third-party cookies:"])},
    "cookie_text_17_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Analytics: Stores cookies to compile statistics about traffic and visit volume to this website. By using this website, you consent to the processing of information about you. Therefore, any exercise of rights in this regard should be communicated directly with Google."])},
    "cookie_text_17_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Networks: Each social network uses its own cookies to allow you to click on buttons like 'Like' or 'Share'."])},
    "cookie_text_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivating or Deleting Cookies"])},
    "cookie_text_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At any time, you can exercise your right to deactivate or delete cookies from this website. These actions are performed differently depending on the browser you are using. To learn more about the browsers, please continue reading."])},
    "cookie_text_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Notes"])},
    "cookie_text_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neither this website nor its legal representatives are responsible for the content or veracity of the privacy policies that may be held by third parties mentioned in this cookie policy. Web browsers are the tools responsible for storing cookies, and from this place, you should exercise your right to eliminate or deactivate them. Neither this website nor its legal representatives can guarantee the correct or incorrect manipulation of cookies by those mentioned."])},
    "cookie_text_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In some cases, it is necessary to install cookies to ensure the browser does not forget your decision of non-acceptance."])},
    "cookie_text_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case of Google Analytics cookies, this company stores cookies on servers located in the United States and commits not to share them with third parties, except where necessary for the functioning of the system or when required by law. According to Google, it does not retain your IP address. Google Inc. is a company adhering to the Safe Harbor Agreement, which guarantees that all transferred data will be treated with a level of protection in accordance with European regulations. You can consult detailed information regarding this at this link. If you would like information on how Google uses cookies, we attach this other link."])},
    "cookie_text_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any questions or inquiries about this cookie policy, please do not hesitate to contact us through the section of the website."])},
    "cookie_text_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is a cookie?"])},
    "cookie_text_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cookie is a harmless text file stored in your browser when you visit almost any webpage. The utility of the cookie is that the website is able to remember your visit when you navigate that page again. Although many people are unaware, cookies have been used for 20 years, since the first browsers appeared for the World Wide Web."])},
    "cookie_text_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is NOT a cookie?"])},
    "cookie_text_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not a virus, trojan, worm, spam, spyware, or pop-up window."])},
    "cookie_text_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What information does a cookie store?"])},
    "cookie_text_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies usually do not store sensitive information about you, such as credit cards or bank data, photographs, ID numbers, or personal information. The data they store is technical in nature, personal preferences, content personalization, etc. The web server does not associate you as a person but rather with your web browser. In fact, if you usually browse with Internet Explorer and try to navigate the same website with Firefox or Chrome, you will see that the website does not recognize you as the same person because, in reality, it is associating with the browser, not the person."])},
    "cookie_text_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What types of cookies exist?"])},
    "cookie_text_32_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical cookies: These are the most basic and allow, among other things, to know when a human or an automated application is browsing, when an anonymous user is browsing, and a registered user, basic tasks for the operation of any dynamic website."])},
    "cookie_text_32_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytical cookies: They collect information about the type of navigation you are performing, the sections you use the most, products consulted, time of use, language, etc."])},
    "cookie_text_32_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising cookies: They show advertising based on your browsing, your country of origin, language, etc."])},
    "cookie_text_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are first-party cookies and third-party cookies?"])},
    "cookie_text_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First-party cookies are generated by the page you are visiting, and third-party cookies are generated by external services or providers like Facebook, Twitter, Google, etc."])},
    "cookie_text_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What happens if I disable cookies?"])},
    "cookie_text_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help you understand the implications of disabling cookies, here are some examples:"])},
    "cookie_text_37_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to share content from that website on Facebook, Twitter, or any other network."])},
    "cookie_text_37_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website will not be able to adapt the content to your personal preferences, as usually happens in stores."])},
    "cookie_text_37_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to access the personal area of that website, such as \"My account,\" or \"My profile,\" or \"My orders.\""])},
    "cookie_text_37_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online stores: You will be unable to make online purchases; they will have to be done by phone or by visiting the physical store, if available."])},
    "cookie_text_37_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your geographical preferences, such as time zone or currency customization, will not be possible."])},
    "cookie_text_37_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website will not be able to perform web analytics on visitors and traffic to the website, which will hinder the website being able to improve."])},
    "cookie_text_37_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to write on the blog, upload photos, publish comments, rate or score. The website will also not be able to know whether you are a human or an automated application publishing spam."])},
    "cookie_text_37_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targeted advertising will not be displayed, reducing the website's advertising revenue."])},
    "cookie_text_37_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All social networks use cookies; if you disable them, you will not be able to use any network."])},
    "cookie_text_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can cookies be deleted?"])},
    "cookie_text_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. Not only can they be deleted, but they can also be blocked, either generally or specifically for a specific domain. To delete cookies from a website, you must go to your browser settings and search for cookies associated with the domain in question and proceed with their deletion."])},
    "cookie_text_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Settings for Popular Browsers"])},
    "cookie_text_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below we show you how to access a specific cookie in the Chrome browser. Note: these steps may vary depending on the version of the browser:"])},
    "cookie_text_42_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Settings or Preferences through the File menu or by clicking the customization icon at the top right."])},
    "cookie_text_42_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will see different sections, click on the \"Show Advanced Options\" option."])},
    "cookie_text_42_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Privacy, then Content Settings."])},
    "cookie_text_42_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"All cookies and site data.\""])},
    "cookie_text_42_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A list will appear with all cookies sorted by domain. To make it easier to find cookies from a certain domain, enter part or all of the address in the field \"Search cookies.\""])},
    "cookie_text_42_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After performing this filter, one or more lines with cookies from the requested website will appear on the screen. Now, you only have to select it and press the X to proceed with deletion."])},
    "cookie_text_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the cookie settings of Internet Explorer, follow these steps (may vary based on browser version):"])},
    "cookie_text_44_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Tools, Internet Options."])},
    "cookie_text_44_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on Privacy."])},
    "cookie_text_44_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the slider to adjust the privacy level you desire."])},
    "cookie_text_45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the cookie settings of Firefox, follow these steps (may vary based on browser version):"])},
    "cookie_text_46_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Options or Preferences depending on your system."])},
    "cookie_text_46_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on Privacy."])},
    "cookie_text_46_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In History, choose \"Use custom settings for history.\""])},
    "cookie_text_46_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you will see the option \"Accept cookies,\" which you can enable or disable based on your preferences."])},
    "cookie_text_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the cookie settings of Safari for OSX, follow these steps (may vary based on browser version):"])},
    "cookie_text_48_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Preferences, then Privacy."])},
    "cookie_text_48_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will see the option \"Block cookies\" to adjust the type of blocking you want."])},
    "cookie_text_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the cookie settings of Safari for iOS, follow these steps (may vary based on the browser version):"])},
    "cookie_text_50_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Settings, then Safari."])},
    "cookie_text_50_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Privacy and Security; you will see the option to Block cookies to adjust the type of blocking you want."])},
    "cookie_text_51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the cookie settings of browsers for Android devices, follow these steps (may vary based on the browser version):"])},
    "cookie_text_52_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run the browser and press the Menu key, then Settings."])},
    "cookie_text_52_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Security and Privacy; you will see the option to Accept cookies to enable or disable."])},
    "cookie_text_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the cookie settings of the browser for Windows Phone devices, follow these steps (may vary based on the browser version):"])},
    "cookie_text_54_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Internet Explorer, then More, then Settings."])},
    "cookie_text_54_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now enable or disable the \"Allow cookies\" checkbox."])}
  },
  "partner_page": {
    "become_a_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a partner"])},
    "partner_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Information"])},
    "account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Information"])},
    "storage_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage address"])},
    "number_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number/Details"])},
    "storage_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage Name"])},
    "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Name"])},
    "VAT_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Number"])},
    "surface_in_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface in m²"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "certifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifications"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking “Submit”, you agree to the Terms and Conditions and the Privacy Policy."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  }
}